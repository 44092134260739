<template>
  <div id="HistoricoDeAtividades">

    <!-- Usado para selecionar o texto consultado e deixar ele HighLight -->
    <input type="hidden" id="hid_search_text" name="hid_search_text">

    <v-toolbar-title class="headline lighten-2 d-flex justify-space-between">

      <!-- TÍTULO DA PÁGINA -->
      <v-toolbar-title :style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mt-1">
        <p class="font-primary font-weight-bold font-weight-medium body-1 " :style="{ 'color':COR_SUBTITULO }">
          Histórico de Atividades
        </p>
      </v-toolbar-title>
      <!-- FIM TÍTULO DA PÁGINA -->

      <!-- BOTÕES DE AÇÃO CABECALHO -->
      <v-toolbar-title
        class="container_btn-header d-flex flex-column mt-0 ml-4 pa-0"
        dark
        elevation="0"
      >
       
      </v-toolbar-title>
      <!-- FIM BOTÕES DE AÇÃO CABECALHO -->
    </v-toolbar-title>
    <v-container class="mx-0 px-0 pt-0">
      <v-row dense class="px-0 pl-1">
        <v-col cols="12">
          <!-- Text Field com Consulta ----------------------------------------->
          <v-container
            class="pa-0 ma-0 mb-3 d-flex"
            style="margin-top: -4px;">
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation=0
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
            <template v-slot:activator="{ on }">
              <div
                ref="vMenuDiv"
                class="false-filter d-flex align-center"
                style="justify-content: space-between;"
                align="center"
                v-on="on">
                <v-icon class="false-icon ml-3 mr-2" :color="COR_SUBTITULO">mdi-magnify</v-icon>
                  <div class="false-text1" style="flex:none">
                    <v-chip
                      v-if="filtro.usuario"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Usuário: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{filtro.usuario.nome}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="filtro.descricao"
                      style="max-width: 250px;" 
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1">
                      <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Descrição: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{filtro.descricao}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="filtro.data_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Data min.: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{moment(filtro.data_min).format('DD/MM/YYYY')}}</strong>
                    </v-chip>
                    <v-chip
                      v-if="filtro.data_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2">
                      <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Data max.: </span>
                      <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{moment(filtro.data_max).format('DD/MM/YYYY')}}</strong>
                    </v-chip>
                  </div>
                  <v-icon class="false-icon mr-4 ml-2" :color="COR_SUBTITULO">mdi-filter-variant-plus</v-icon>
                </div>
              </template>
              <v-container 
                :style="{ 'background-color':COR_SECUNDARIA_2 }"
                style="min-width:800px!important; max-width:1000px!important">
                <v-row no-gutters class="linhas">
                  <v-col md="6">
                    <v-autocomplete
                      v-model="filtro_local.usuario"
                      :items="array_usuarios"
                      item-text = "nome"
                      item-value = "cod_usuario"
                      label="Usuários"
                      placeholder="Usuários"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"/>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.descricao"
                      label="Descrição"
                      placeholder="Descrição"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" disabled>
                    <DatePicker
                      id="txt_data_min"
                      ref="txt_data_min"
                      v-model="filtro_local.data_min"
                      label="Data Inicial"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      dense>
                    </DatePicker>
                  </v-col>
                  <v-col md="6" disabled>
                    <DatePicker
                      id="txt_data_max"
                      ref="txt_data_max"
                      v-model="filtro_local.data_max"
                      label="Data Final"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      dense>
                    </DatePicker>
                  </v-col>
                  
                </v-row>
              </v-container>
              <v-divider></v-divider>

              <!-- Botao Limpar ------------------------------------>
              <div 
                class="pa-4 d-flex justify-space-between"
                :style="{ 'background-color':COR_SECUNDARIA }">
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                  >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-close</v-icon
                  >
                  <span>Limpar Todos</span>
                </v-btn>
                          
                <!-- Botao Consultar ------------------------------------>
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="consulta()"
                  >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-magnify</v-icon
                  >
                  <span>Consultar</span>
                </v-btn>
              </div>  
            </v-menu>  

      
          </v-container>
          <!-- ----------------------------------------------- -->
          </v-col>
        <v-col cols="12">
          <v-timeline class="pa-2">
            <v-timeline-item
              class="pb-0"
              v-for="item in array_ocorrencias"
              :key="item.cod_ocorrencia"
              large>
              <!-- <pre>{{ item.usu_ocor }}</pre> -->
              <template v-slot:icon>
                <v-avatar>
                  <img
                    v-if="item.usu_ocor.imagem_caminho_usuario"
                    :src="`${baseURL}${item.usu_ocor.imagem_caminho_usuario}`"
                    :title="item.usu_ocor.pessoa_nome">
                    <v-icon v-else color="white">mdi-account</v-icon>
                </v-avatar>
              </template>
              <v-card class="elevation-0">
                <v-card-title class="pa-2 pb-0" style="font-size:1rem">
                  <strong v-html="item.titulo"></strong>
                  
                </v-card-title>
                <v-card-text class="pa-2 pt-1">
                  <div v-if="item.tipo_operacao === 'Inclusão'">
                    <v-icon color="green">mdi-plus</v-icon> <strong>{{ formatDateTime(item.data_hora) }}</strong>
                  </div>
                  <div v-if="item.tipo_operacao === 'Alteração'">
                    <v-icon color="green">mdi-pencil</v-icon> <strong>{{ formatDateTime(item.data_hora) }} - {{ item.usu_ocor.pessoa_nome }}</strong>
                  </div>
                  <div v-if="item.tipo_operacao === 'Exclusão'">
                    <v-icon color="red">mdi-delete</v-icon> <strong>{{ formatDateTime(item.data_hora) }}</strong>
                  </div>
                  <div 
                    v-for="historico in (item.ocorr_hist)"
                    :key="historico.cod_crm_ocorrencia_historico">
                    <div class="mx-5 mt-2">
                      <v-divider class="mb-2"></v-divider>
                      <p class="mb-0"><strong>{{ historico.titulo }}</strong></p>
                      <!-- <pre>aaaa{{ (historico.descricao) }}</pre> -->
                      <!-- <pre>{{ historico.descricao }}</pre> -->
                      <div v-if="validarJSON(historico.descricao)">
                        <div v-for="descricao, i in JSON.parse(historico.descricao)"
                          :key="i" class="ml-5">
                          <p>
                            {{ descricao.campo }} 
                            de:  
                            <b>
                              {{ descricao.de }}
                            </b> 
                            para: 
                            <b>
                              {{ descricao.para }}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <template>
        <div class="paginacao d-flex justify-end align-center">
          <span style="color:grey; font-size:14px" class="mr-3">{{count_dados}} linhas</span>
          <v-pagination
            v-model="page"
            :length="paginas"
            @input="handlePageChange"
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import store_site from "../../../store/store_site"
import store_usuario from "../../../store/store_usuario";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO } from "../../../services/constantes";
import {formatDate, 
        formatDateTime, 
        searchHighlight,
       } from "../../../services/funcoes";
import { baseURL, API } from "../../../services/API";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import moment from 'moment';

export default {
  name: "HistoricoDeAtividades",

  props: {
    parametro: {
      type: Object,
      required: true
    }
  },
  components: {
    DatePicker
  },

  data() {
    return {
      store_site        : store_site,
      store_usuario     : store_usuario,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SECUNDARIA_2  : COR_SECUNDARIA_2,

      array_ocorrencias : [],
      array_usuarios    : [],
      filteredOcorrencias : [],
      menu              : false,
      loading           : false,
      formatDate        : formatDate,
      formatDateTime    : formatDateTime,
      baseURL           : baseURL,
      page              : 1,
      paginas           : 1,
      currentPage       : 1,
      count_dados       : 0,

      filtro            : {},
      filtro_local      : {},
    };
  },

  async mounted(){
    await this.arrayUsuarios()
    await this.carregaOcorrencias()
  },
  methods: {
    validarJSON(json) {
      try {
        JSON.parse(json);
        return true;
      } catch (error) {
        console.log("🚀 ~ JSON com erro:", error)
        return false;
      }
    },

    async arrayUsuarios(){
      const resp = await API.get(`usuario_sem_paginate`, {});
      const { data } = resp;
      if (resp.status == 200){
        this.array_usuarios = data.result
      } 
      else 
        this.array_usuarios = []
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.carregaOcorrencias();
    },

    consulta(){
      const { descricao, data_min, data_max } = this.filtro_local;      
      const codUsuario = this.filtro_local?.usuario?.cod_usuario
      // console.log("🚀 ~ filtro:", this.filtro_local)

      let filtro = {}
      if (!!codUsuario) {
        // console.log("🚀 ~ file: HistoricoAtividades.vue:352 ~ consulta ~ codUsuario:", codUsuario)
        filtro.cod_usuario = codUsuario
      }

      if (!!descricao) {
        filtro.descricao = descricao
      }

      if (!!data_min) {
        filtro.data_min = data_min
      }

      if (!!data_max) {
        filtro.data_max = data_max
      }
      
      this.carregaOcorrencias(filtro)
    },

    limpa_todos() {
      for(let prob in this.filtro_local){
        this.filtro_local[prob] = null
      }
    },

    abriuConsulta(val) {
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.filtro};
          // console.log("🚀 ~ ABRIU CONSULTA111:", this.filtro_local, this.filtro)
        });
      }
    },

    async carregaOcorrencias(p_params = {}){
      // console.log("🚀 ~ file: HistoricoAtividades.vue:394 ~ carregaOcorrencias ~ p_params:", p_params)
      this.loading = true
      let parametro = {}
      parametro = { ...this.parametro, page: this.currentPage }
      if (Object.keys(p_params).length === 0) {
      }
      else{
        Object.assign(parametro, p_params)
      }
      this.filtro = {}
      this.filtro = await {...this.filtro_local};
      const resp = await API.get(`ocorrencia`, { params:  parametro });
      const { data } = resp;
      if (resp.status == 200 && resp.data.result !== 'Não possui dados') {
       
        // metodo para fazer o hilight
        clearTimeout(this._searchTimerId_3);
        this._searchTimerId_3 = setTimeout(() => {
          searchHighlight('HistoricoDeAtividades', p_params.descricao);
        }, 100);

        this.array_ocorrencias = data.result.rows
        this.count_dados = data.result.count
        this.paginas = data.result.paginas
      }
      else {
        this.array_ocorrencias = [];
        this.count_dados = 0
        this.paginas = 0
      }
      console.log('array_ocorrencias', this.array_ocorrencias);
      this.menu = false
      this.loading = false
      // console.log('array_ocorrencias',typeof JSON.parse(this.array_ocorrencias[0].historico[0].descricao));
    },

    jsonValido(descricao){
      try {
        JSON.parse(descricao);
        return true;
      } catch (error) {
        return false;
      }
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    }
  },
};
</script>

<style scoped>
#HistoricoDeAtividades {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* ---------- BARRA DE SCROLL ---------- */
#HistoricoDeAtividades::-webkit-scrollbar {
  width: 5px;
}

#HistoricoDeAtividades::-webkit-scrollbar-button {
  padding: 1px;
}

#HistoricoDeAtividades::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#HistoricoDeAtividades::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.title-page {
  font-family: "Open Sans", sans-serif!important;
  letter-spacing: 0px;
  font-size: 18px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}

.search-input, .search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px!important;
  min-width: 400px!important;
}

@media(max-width: 1024px) {
 .search-input, .search-input-longo {
    max-width: 250px!important;
    min-width: 250px!important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }

  .container_btn-header {
    margin-left: 0px!important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
    /* max-width: calc(100% - 15px)!important; */
  }
}

@media(max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto!important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}
  
.select-order {
  max-width: 210px;
}

@media(max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px!important;
  }

  .select-order {
    margin-top: 12px!important;
    margin-right: 0px!important;
  }
}

@media(max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}

.col-wrapper {
  max-width: 196px;
}

@media(max-width: 1024px) {
  .col-wrapper {
     max-width: 30%!important;
  }
}

@media(max-width: 768px) {
  .col-wrapper {
     max-width: 45%!important;
  }
}

@media(max-width: 599px) {
  .col-wrapper {
     max-width: 46%!important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px!important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  height: 250px;
}

@media(max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media(max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  } 
}

@media(max-width: 599px) {
  .document-name {
    max-width: 8ch;
  } 
}

@media(max-width: 375px) {
  .document-name {
    max-width: 6ch;
  } 
}

@media(max-width: 360px) {
  .document-name {
    max-width: 5ch;
  } 
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0!important;
  visibility: hidden!important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2); 
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible!important;
  opacity: 1!important;
}

.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media(max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}

.btn-icon:hover {
  background: #FFF;
}

.icon-action {
  font-size: 30px!important;
}

.false-filter {
    background-color:#FFFFFF;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
  }

  .false-text {
    width: 78%;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

@media(max-width: 599px) {
  .icon-action {
    font-size: 20px!important;
  }
}
</style>